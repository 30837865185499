.section__customers {
  position: relative;

  & .ant-form {
    & .form-group-address {
      display: grid;
      grid-template-columns: 1fr 50px;
      column-gap: 16px;

      & .addresses {
        display: grid;
        grid-template-columns: 1fr;
      }

      & .item {
        margin: 0;
        display: flex;
        align-items: center;
        border-radius: 6px;
        transition: border ease 0.3s;
        user-select: none;
        height: 34px;
        padding: 0 8px;
        background-color: $light10;
        justify-content: space-between;

        & p {
          margin: 0;
        }

        & button {
          width: 24px;
          height: 24px;

          & .icon {
            width: 100%;
            height: 100%;
            display: flex;
            background-color: $white;
            border-radius: 50%;

            & img {
              display: block;
              width: 16px;
              margin: auto;
            }
          }
        }

        &:hover {
          border-color: $blue40;
        }
      }
    }
  }

  & .wrapper-address {
    background-color: $light10;
    border-radius: 6px;
    padding: 16px;
    margin-bottom: 20px;
  }
}

.customers {
  .section__header {
    display: flex;
    justify-content: space-between;

    >h1 {
      margin: 0;
    }
  }
}
.available-positions {

  .section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >h1 {
      margin: 0;
    }
  }
}

.edit-position {

  .section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >h1 {
      margin: 0;
    }
  }
}

.working-at-auxality {

  .section__header {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    >h1 {
      margin: 0;
    }
  }
}

.locations {

  .section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >h1 {
      margin: 0;
    }
  }

  .locations-title {
    font-size: 24px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 12px;
  }
}

.edit-content {

  .section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      margin: 0;
    }
  }
}
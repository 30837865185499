.main__layout {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  @media (min-width: 1200px) {
    grid-template-columns: 320px 1fr;
    grid-template-rows: 1fr;
  }
}

.gl__article {
  position: absolute;
  top: 64px;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 30px 20px;

  @media (min-width: 1200px) {
    top: 0;
    left: 320px;
    padding: 40px 30px;
  }
}
.products-and-services,
.edit-product {

  .section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      margin: 0;
    }

    & .right {
      display: flex;
      grid-gap: 15px;
    }
  }

  &.ant-btn,
  .ant-btn-primary {
    margin-bottom: 30px;
  }

}

.ant-form-item {
  margin: 0;
}